html {
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
caption,
canvas,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
form,
footer,
header,
hgroup,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
tt,
table,
tbody,
textarea,
tfoot,
thead,
time,
tr,
th,
td,
u,
ul,
var,
video {
    font-size: 100%;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    quotes: none;
}

figure {
    margin: 0;
}

:focus {
    outline: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

legend {
    white-space: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

button,
input {
    line-height: normal;
}

input,
textarea {
    background-image: -webkit-linear-gradient(
        hsla(0, 0%, 100%, 0),
        hsla(0, 0%, 100%, 0)
    );
    /* Removing the inner shadow, rounded corners on iOS inputs */
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    line-height: 1;
    cursor: pointer;
    /* Improves usability and consistency of cursor style between image-type 'input' and others */
    -webkit-appearance: button;
    /* Corrects inability to style clickable 'input' types in iOS */
    border: none;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
    /* Addresses excess padding in IE8/9 */
}

input[type="search"] {
    -webkit-appearance: textfield;
    /* Addresses appearance set to searchfield in S5, Chrome */
}

input[type="search"]::-webkit-search-decoration {
    /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
    border: 0;
    padding: 0;
}

label {
    margin-bottom: 5px;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* Repeatable Patterns
  -------------------------------------------------------------- */
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font: 16px/20px "Porsche Next TT", sans-serif;
    font-weight: 500;
    background-color: $white_color;
    color: $secondary_color;
    overflow: hidden;
    padding-right: 0 !important;
}

a {
    color: $on_surface_color;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
    color: $primary_color;
    text-decoration: none;
    outline: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

ul,
ol {
    padding: 0;
}

img {
    width: 100%;
    height: auto;
}

b,
strong {
    font-weight: 900;
}

h1 {
    font-size: 32px;
    line-height: 40px;
}

h2 {
    font-size: 18px;
    line-height: 26px;
}

h3 {
    font-size: 16px;
    line-height: 24px;
}

h4 {
    font-size: 14px;
    line-height: 22px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    color: $on_surface_color;
}

button {
    border: none;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    width: 100%;
    padding: 12px 40px;
    font-weight: $fw_6;
    font-size: 16px;
    line-height: 24px;
    background-color: #ffffff;
    border: 1px solid $primary_color;
    color: $white_color;
    text-transform: capitalize;
    text-align: center;
    border-radius: 8px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

button:hover {
    color: $primary_color;
    border-color: $primary_color;
    background-color: $white_color;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    width: 100%;
    font-size: 14px;
    font-weight: $fw_5;
    border: 1px solid $line_color;
    color: $on_surface_color;
    background: $white_color;
    padding: 13px 14px;
    border-radius: 8px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: $primary_color;
}

textarea:-moz-placeholder,
textarea::-moz-placeholder,
input:-moz-placeholder,
input::-moz-placeholder {
    color: $secondary2_color;
    opacity: 1;
    font-weight: $fw_5;
    font-size: 14px;
    line-height: 22px;
}

input:-ms-input-placeholder {
    color: $secondary2_color;
    font-weight: $fw_5;
    font-size: 14px;
    line-height: 22px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    color: $secondary2_color;
    opacity: 1;
    font-weight: $fw_5;
    font-size: 14px;
    line-height: 22px;
}

textarea,
input[type="text"],
input[type="submit"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.dropdown-menu,
.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
    -webkit-appearance: none;
    text-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}

input[type="radio"] {
    width: 16px;
    height: 16px;
    border-color: $secondary_color;
    cursor: pointer;
    font-size: 16px;
    accent-color: $primary_color;
}

// spacing
.tf-spacing-4 {
    height: 4px;
}

.mt-1 {
    margin-top: 4px !important;
}

.mb-1 {
    margin-bottom: 4px !important;
}

.tf-spacing-8 {
    height: 8px;
}

.mt-2 {
    margin-top: 8px !important;
}

.mb-2 {
    margin-bottom: 8px !important;
}

.tf-spacing-12 {
    height: 12px;
}

.mt-3 {
    margin-top: 12px !important;
}

.mb-3 {
    margin-bottom: 12px !important;
}

.tf-spacing-16 {
    height: 16px;
}

.mt-4 {
    margin-top: 16px !important;
}

.mb-4 {
    margin-bottom: 16px !important;
}

.tf-spacing-20 {
    height: 20px;
}

.mt-5 {
    margin-top: 20px !important;
}

.mb-5 {
    margin-bottom: 20px !important;
}

.tf-spacing-24 {
    height: 24px;
}

.mt-6 {
    margin-top: 24px !important;
}

.mb-6 {
    margin-bottom: 24px !important;
}

.tf-spacing-40 {
    height: 40px;
}

.mt-7 {
    margin-top: 40px !important;
}

.mb-7 {
    margin-bottom: 40px !important;
}

.tf-spacing-60 {
    height: 60px;
}

.mt-8 {
    margin-top: 60px !important;
}

.mb-8 {
    margin-bottom: 60px !important;
}

.tf-spacing-80 {
    height: 80px;
}

.mt-9 {
    margin-top: 80px !important;
}

.mb-9 {
    margin-bottom: 80px !important;
}

.group-input {
    margin-bottom: 24px;

    &.last {
        margin-bottom: 0;
    }
}

// switch-check
.tf-switch-check {
    position: relative;
    height: 30px;
    width: 56px;
    -webkit-appearance: none;
    background: $secondary2_color;
    border-radius: 100px;
    transition: 0.5s;
    margin-right: 0;

    &::before {
        content: "";
        border-radius: 100px;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 3px;
        left: 4px;
        background: $white_color;
        transition: 0.5s;
    }

    &:checked:checked[type="checkbox"] {
        background: $primary_color;
        transition: 0.5s;

        &::before {
            background: $white_color;
            left: 28px;
        }
    }
}

// checkbox

.tf-checkbox {
    position: relative;
    border: 1px solid $line_color;
    border-radius: 6px;
    background: none;
    cursor: pointer;
    outline: 0;
    height: 24px;
    width: 24px;
    -webkit-appearance: none;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    &:checked {
        background-color: #000;
        opacity: 1;
    }

    &::before {
        content: "";
        position: absolute;
        right: 50%;
        top: 50%;
        width: 6px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        margin: -1px -1px 0 -1px;
        transform: rotate(45deg) translate(-50%, -50%);
        z-index: 2;
    }
}

.tf-checkbox.smail {
    width: 22px;
    height: 22px;
}
