// color
$primary_color: #000;
$on_surface_color: #1e1e1e;
$secondary_color: #717171;
$secondary2_color: #c5c5c5;
$secondary3_color: #f8f8f8;
$line_color: #dddddd;
$line_br_color: #ededed;
$surface_color: #f5f5f5;
$warning_color: #f2c71c;
$success_color: #44b461;
$critical_color: #ea3434;
$white_color: #ffffff;
$light_color: #999999;
$bg_light_color: #dfdfdf;
$primary_rgba_color: rgba(33, 150, 243, 1);
$tf_shadow: 0px 8px 15px 0px rgba(24, 25, 27, 0.05);
$tf-navigation-bar_shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
$rgba_primary_color: rgba(75, 40, 225, 0.1);
$bg_primary: rgba(38, 98, 229, 0.2);
$bg_critical: rgba(235, 90, 90, 0.2);
$rgba_transfer_color: rgba(83, 61, 234, 0.1);
$bg_color_1: rgba(40, 62, 180, 0.1);
$bg_color_2: rgba(5, 132, 255, 0.1);
$bg_color_3: rgba(0, 170, 91, 0.1);
$bg_color_4: rgba(242, 195, 28, 0.1);
$bg_color_5: rgba(245, 149, 0, 0.1);
$bg_color_6: rgba(57, 163, 248, 0.1);
$bg_color_7: rgba(229, 53, 75, 0.1);
$bg_color_8: rgba(252, 184, 96, 0.1);
$bg_color_9: rgba(57, 198, 93, 0.2);
$bg_color_10: rgba(201, 126, 103, 0.2);
$bg_color_11: rgba(24, 168, 201, 0.1);
$bg_color_12: rgba(229, 99, 83, 0.2);
$bg_service-1: rgba(198, 242, 178, 0.5);
$bg_service-2: rgba(199, 238, 247, 0.5);
$bg_service-3: rgba(241, 242, 178, 0.5);
$bg_service-4: rgba(244, 204, 181, 0.5);
$bg_service-5: rgba(167, 220, 232, 0.5);
$bg_service-6: rgba(83, 61, 234, 0.2);
$bg_service-7: rgba(193, 19, 35, 0.1);
$bg_service-8: rgba(178, 242, 215, 0.5);

$bg_popup: rgba(2, 22, 56, 0.3);

// font-weight
$fw_3: 300;
$fw_4: 400;
$fw_5: 500;
$fw_6: 600;
$fw_7: 700;
$fw_8: 800;

.primary_color {
    color: $primary_color;
}

.on_surface_color {
    color: $on_surface_color;
}

.secondary_color {
    color: $secondary_color;
}

.secondary2_color {
    color: $secondary2_color;
}

.secondary3_color {
    color: $secondary3_color;
}

.line_color {
    color: $line_color;
}

.line_br_color {
    color: $line_br_color;
}

.surface_color {
    color: $surface_color;
}

.warning_color {
    color: $warning_color;
}

.success_color {
    color: $success_color;
}

.critical_color {
    color: $critical_color;
}

.white_color {
    color: $white_color;
}

.tf_shadow {
    box-shadow: $tf_shadow;
}

// font-weight
.fw_3 {
    font-weight: $fw_3;
}

.fw_4 {
    font-weight: $fw_4;
}

.fw_5 {
    font-weight: $fw_5;
}

.fw_6 {
    font-weight: $fw_6;
}

.fw_7 {
    font-weight: $fw_7;
}

.fw_8 {
    font-weight: $fw_8;
}

.bg_white_color {
    background-color: $white_color;
}

.bg_surface_color {
    background: $surface_color;
}

.bg_qr_profile {
    background: #020202;
}

.bg_gift-card-1 {
    background: $secondary3_color;
}

.bg_gift-card-2 {
    background: $on_surface_color;
}

.bg_primary {
    background: $bg_primary;
}

.bg_critical {
    background: $bg_critical;
}

.bg_color_1 {
    background: $bg_color_1;
}

.bg_color_2 {
    background: $bg_color_2;
}

.bg_color_3 {
    background: $bg_color_3;
}

.bg_color_4 {
    background: $bg_color_4;
}

.bg_color_5 {
    background: $bg_color_5;
}

.bg_color_6 {
    background: $bg_color_6;
}

.bg_color_7 {
    background: $bg_color_7;
}

.bg_color_8 {
    background: $bg_color_8;
}

.bg_color_9 {
    background: $bg_color_9;
}

.bg_color_10 {
    background: $bg_color_10;
}

.bg_color_11 {
    background: $bg_color_11;
}

.bg_color_12 {
    background: $bg_color_12;
}

.bg_service-1 {
    background: $bg_service-1;
}

.bg_service-2 {
    background: $bg_service-2;
}

.bg_service-3 {
    background: $bg_service-3;
}

.bg_service-4 {
    background: $bg_service-4;
}

.bg_service-5 {
    background: $bg_service-5;
}

.bg_service-6 {
    background: $bg_service-6;
}

.bg_service-7 {
    background: $bg_service-7;
}

.bg_service-8 {
    background: $bg_service-8;
}
