.login-section {
    .tf-form {
        margin-bottom: 71px;
        h1 {
            margin-bottom: 28px;
        }
        .group-input {
            input {
                background-color: #ffffff;
            }
        }
    }

    .auth-line {
        margin-bottom: 24px;
    }

    // login with social
    .socials-login {
        li {
            padding: 13px 0px;
            border: 1px solid $line_color;
            border-radius: 12px;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
            &:hover {
                border-color: $primary_color;
            }
        }
    }
    .tf-btn {
        margin-top: 28px;
    }
    .auth-link-rg {
        color: $on_surface_color;
        font-size: 14px;
        line-height: 22px;
        font-weight: $fw_6;
        margin-left: 8px;
    }
}

.register-section {
    .tf-form {
        h1 {
            margin-bottom: 28px;
        }
        .group-cb {
            align-items: center;
            .tf-checkbox {
                display: block;
                width: 16px;
                height: 16px;
                &:checked {
                    background-color: $primary_color;
                }
                &::before {
                    right: 43%;
                    top: 55%;
                    width: 5px;
                    height: 8px;
                }
            }
            label {
                margin: 0;
                color: $on_surface_color;
                font-weight: $fw_4;
                a {
                    font-weight: 500;
                }
            }
        }
        .tf-btn {
            margin-top: 58px;
        }
    }
}

.verify-account-section {
    .image-box {
        padding: 24px 0px;
        max-width: 375px;
        margin: 0px auto;
        height: 358px;
        overflow: hidden;
    }
    .tf-content{
      p{
        padding: 0px 30px;
      }  
    }
}
.reset-pass-section {
    .tf-title{
        p{
            padding-top: 9px;
            font-size: 14px;
            line-height: 22px;
            font-weight: $fw_4;
        }
    }
    .image-box {
        margin: 75px auto;
        max-width: 343px;
        height: 190px;
        overflow: hidden;
    }
    .tf-form{
        .tf-btn {
            margin-top: 120px;
            margin-bottom: 60px;
        }
    }
}
.newpass-section {
    .tf-form {
        h1{
            margin-bottom: 28px;
            padding-right: 10px;
        }
        .tf-btn{
            margin-top: 28px;
        }

    }
}
.pw-confirm-section {
    .image-box{
        max-width: 375px;
        margin: 0px auto;
        height: 274px;
        margin-bottom: 48px;
    }
    .tf-content{
        p{
            font-size: 14px;
            line-height: 22px;
            font-weight: $fw_4;
        }
    }
    .tf-btn{
        margin: 128px 0px 53px;
    }
}
.tf-form-verify {
    .bottom-btn-fixed{
        width: unset;
        left: 16px;
        right: 16px;
    }
}