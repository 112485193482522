.boarding-section {
    background: $on_surface_color;
    min-height: 310px;
    margin: 0 -45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 300px;
    border-bottom-right-radius: 300px;

    .images {
        img {
            width: 307px;
            height: 265px;
        }
    }
}

.boarding-section.st2 {
    position: relative;

    .images {
        position: relative;
        z-index: 1;
        margin-left: 90px;
    }

    &::after {
        content: "";
        position: absolute;
        height: 215px;
        bottom: 0;
        width: 100%;
        background-color: #000;
        border-radius: 50%;
    }
}

.boarding-section.st3 {
    position: relative;

    .images {
        position: relative;
        z-index: 1;

        img {
            width: 375px;
            height: 320px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        height: 215px;
        bottom: 0;
        width: 100%;
        background-color: #000;
        border-radius: 50%;
        transform: skew(-14deg, 8deg);
    }
}

.boarding-content {
    .boarding-title {
        text-align: center;
        padding-bottom: 60px;

        h1 {
            margin-bottom: 16px;
        }

        p {
            padding: 0 30px;
        }

        &.st1 {
            h1 {
                padding: 0px 30px;
            }
        }
    }

    .bottom {
        text-align: center;
        padding: 0px 50px 24px;
        margin-top: 30px;

        a {
            font-weight: 700;
        }
    }
}
