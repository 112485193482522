.tf-container,
.fixed-container {
    max-width: 1320px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
}

#app-wrap {
    padding: 50px 0px 62px;

    &.style1 {
        padding-bottom: 0px;
    }
}

.is-fixed {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
}

.dots-tes {
    position: unset;

    span {
        width: 5px;
        height: 5px;
    }

    .swiper-pagination-bullet-active {
        background: $primary_color;
    }
}

.back-btn,
.icon-close {
    min-width: 44px;
    height: 50px;
    display: flex;
    align-items: center;
}

.icon-close1 {
    position: absolute;
    left: 0;
    min-width: 44px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $on_surface_color;
}

// button
.tf-btn {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.tf-btn.accent {
    background-color: $primary_color;
    border: 1px solid $primary_color;
    color: $white_color;

    &:hover {
        color: $primary_color;
        border-color: $primary_color;
        background-color: $white_color;
    }
}

.tf-btn.outline {
    color: $primary_color;
    border: 1px solid $primary_color;
    background-color: $white_color;

    &:hover {
        background-color: $primary_color;
        border: 1px solid $primary_color;
        color: $white_color;
    }
}

.tf-btn.light {
    color: $light_color;
    background-color: $bg_light_color;

    &:hover {
        background-color: $primary_color;
        color: $white_color;
    }
}

.tf-btn.large {
    padding: 12px 40px;
}

.tf-btn.medium {
    padding: 10px 40px;
}

.tf-btn.small {
    padding: 7px 40px;
}

.line {
    display: block;
    width: 134px;
    height: 5px;
    border-radius: 100px;
    background: $line_color;
    margin: 0 auto;
}

// form auth
// form
.tf-form {
    .tf-title {
        margin-top: 20px;
        margin-bottom: 28px;
    }

    .group-input {
        // width: 100%;
        position: relative;

        label {
            position: absolute;
            top: -11px;
            background-color: #ffff;
            padding: 0px 5px;
            left: 12px;
        }
    }

    .auth-pass-input {
        .password-addon {
            position: absolute;
            right: 14px;
            top: 14px;
            font-size: 18px;
        }
    }

    .auth-forgot-password {
        color: $secondary_color;
        display: inline-block;
    }

    .btn-large {
        margin-top: 28px;
    }
}

.tf-form-verify {
    .tf-title {
        font-size: 16px;
        line-height: 24px;
    }

    .group-input-verify {
        margin: 0px 0px 12px;
        justify-content: center;

        .input-verify {
            display: block;
            width: 58px;
            height: 58px;
            margin-right: 12px;
            text-align: center;
            font-size: 22px;
            line-height: 30px;
            font-weight: 600;

            &:last-child {
                margin-right: 0;
            }

            &:focus {
                border-color: $on_surface_color;
            }
        }
    }

    .text-send-code {
        p {
            font-size: 14px;
            line-height: 22px;
            display: flex;
            justify-content: center;
        }
    }
}

.icon-box svg {
    width: 25px;
}

// tf-balance-box

.tf-balance-box {
    padding: 12px 0px 0;
    background-color: $white_color;
    border-radius: 15px;
    box-shadow: $tf_shadow;

    .balance {
        padding: 0px 16px 8px;
        border-bottom: 1px solid $line_br_color;

        .br-right {
            border-right: 1px solid $line_br_color;
        }

        .inner-right {
            text-align: end;

            h3 {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .icon-gift-box {
                    display: inline-flex;
                    margin-right: 4px;
                }

                span {
                    margin-left: 2px;
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 500;
                    color: $secondary_color;
                }
            }
        }
    }

    .wallet-footer {
        margin-top: 12px;

        .wallet-card-item {
            width: 25%;

            a {
                display: flex;
                flex-direction: column;
                align-items: center;

                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 40px;
                }

                .icon-my-qr {
                    font-size: 33px;
                }
            }
        }
    }
}

// custom icons
.icon-history {
    border: 1px solid $secondary_color;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    display: inline-flex;

    &::after {
        content: "\eae7";
        left: -6px;
        position: absolute;
        top: -5px;
        font-size: 29px;
        color: $secondary_color;
    }
}

.icon-scan-qr-code {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: $on_surface_color;
    border-radius: 50%;

    &::before {
        content: "\ea7d";
        color: $white_color;
        font-size: 20px;
    }
}

.tf-navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 8px;

    li {
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            font-size: 10px;
            line-height: 16px;

            &:focus,
            &:active,
            &:hover {
                color: $on_surface_color;
            }
        }

        &.active {
            a {
                color: $on_surface_color;

                .icon-history {
                    border-color: $primary_color;
                    background: $primary_color;

                    &::after {
                        color: $white_color;
                    }
                }

                i {
                    color: $primary_color;
                }
            }
        }
    }
}

.bottom-navigation-bar {
    .line {
        margin: 4px auto 0px;
    }
}

// tf-search bar
.input-field {
    position: relative;

    .search-field {
        padding-left: 40px;
        background: $surface_color;
        border-color: $line_br_color;

        &:focus {
            border-color: $line_br_color;
        }

        &:valid ~ .icon-clear {
            display: block;
        }
    }

    .st1 {
        padding-left: 14px;
        background: none;
        border-color: $line_color;

        &:focus {
            border-color: $primary_color;
        }
    }

    .icon-search {
        position: absolute;
        font-size: 25px;
        left: 9px;
        top: 10px;
        color: $on_surface_color;
    }

    .icon-clear {
        display: none;
        position: absolute;
        top: 14px;
        font-size: 18px;
        right: 14px;
        color: $secondary2_color;
    }
}

// tf-statusbar
.tf-statusbar {
    background: $white_color;
    padding: 0px 16px;
    margin: 0px -16px;
    border-bottom: 1px solid $line_br_color;
    position: relative;
    height: 50px;

    .back-btn {
        left: 16px;
        position: absolute;
    }

    .action-right {
        right: 16px;
        position: absolute;
        min-width: 44px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .icon-qrcode4 {
        font-size: 24px;
        color: $on_surface_color;
    }
}

.icon-notification1 {
    color: $on_surface_color;
    position: relative;

    span {
        font-family: "Plus Jakarta Sans", sans-serif;
        position: absolute;
        font-size: 10px;
        line-height: 9px;
        color: $white_color;
        width: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
        background: $critical_color;
        border-radius: 50%;
        right: -1px;
        top: 1px;
    }
}

//custom select
.box-custom-select {
    .default-select {
        display: none;
    }

    #box-select {
        padding-left: 0px;
    }

    #box-select img,
    .btn-select img {
        width: 28px;
        height: 28px;
    }

    #box-select li {
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: $on_surface_color;
        padding: 13px 14px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        position: relative;

        &:hover {
            background-color: $secondary3_color;
        }
    }

    #box-select li span,
    .btn-select li span {
        margin-left: 16px;
    }

    .banks-select {
        display: none;
        border: 1px solid $line_color;
        border-radius: 5px;
        margin-top: 2px;
    }

    .open {
        display: show !important;
    }

    .btn-select {
        width: 100%;
    }

    .lang-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid $line_color;
        color: #1e1e1e;
        padding: 8px 14px;
        border-radius: 8px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
}

.group-input {
    .credit-card {
        position: absolute;
        display: flex;
        align-items: center;
        gap: 4px;
        top: 12px;
        right: 14px;
        color: $primary_color;
        font-size: 10px;
        line-height: 16px;

        i {
            font-size: 16px;
        }
    }

    .icon-info {
        position: absolute;
        right: 12px;
        top: 14px;
        font-size: 20px;
        color: $secondary2_color;
    }
}

.group-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;

    .tf-checkbox.st1 {
        width: 16px;
        height: 16px;

        &:checked {
            background-color: $primary_color;
        }

        &::before {
            right: 42%;
            top: 52%;
            width: 5px;
            height: 7px;
        }
    }
}

// tf-service-item
.tf-service-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;

    .logo {
        width: 44px;
        height: 44px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
        }
    }
}

.tf-card-block {
    padding: 10px 16px;
    border-radius: 8px;
    box-shadow: $tf_shadow;
    background: $white_color;

    .inner {
        gap: 16px;

        .logo {
            font-size: 28px;
            margin: 0px 8px;
            color: $primary_color;
        }
    }

    .logo-img {
        width: 48px;
        height: 32px;

        img {
        }
    }
}

// popup
.tf-panel {
    .panel-box {
        position: fixed;
        z-index: 1300;
        opacity: 0;
        visibility: hidden;
        background: $white_color;
        will-change: transform;
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;
    }

    .panel-center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .panel-top-up,
    .panel-up {
        width: 100%;
        bottom: 0;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
        -moz-tranform: translateY(100%);
    }

    .panel-down {
        width: 100%;
        top: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        -moz-tranform: translateY(-100%);
    }

    .panel-left {
        top: 0;
        left: 0;
        width: 300px;
        height: 100vh;
        overflow: auto;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-tranform: translateX(-100%);
    }

    .panel-right {
        top: 0;
        right: 0;
        width: 300px;
        height: 100vh;
        overflow: auto;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-tranform: translateX(100%);
    }

    .panel_overlay,
    .overlay-inner {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1200;
        background: $bg_popup;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;
    }

    &.panel-open {
        .panel-box,
        .panel_overlay,
        .overlay-inner {
            opacity: 1;
            visibility: visible;
        }

        .panel-top-up,
        .panel-down,
        .panel-up {
            transform: translateY(0%);
            -webkit-transform: translateY(0%);
            -moz-tranform: translateY(0%);
        }

        // .panel-down{

        //     transform: translateY(100%);
        //     -webkit-transform: translateY(100%);
        //     -moz-tranform: translateY(100%);
        // }
        .panel-left,
        .panel-right {
            transform: translateX(0%);
            -webkit-transform: translateX(0%);
            -moz-tranform: translateX(0%);
        }
    }
}

.modal-dialog.modal-dialog-centered {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 1rem);
    margin: 0px auto;
}

// content panel
.wrap-content-panel {
    .heading {
        padding: 17px 0px;
        border-bottom: 1px solid $line_color;
    }

    .main-topup {
        padding: 16px 0px;

        h3 {
            padding-bottom: 12px;
        }

        .tf-card-block {
            padding: 16px;
            background-color: $line_br_color;
            margin-bottom: 8px;

            .icon-right {
                font-size: 18px;
                color: $on_surface_color;
            }
        }

        .info {
            margin-bottom: 8px;

            li {
                padding: 12px 0px;
                border-bottom: 1px solid $line_br_color;
            }
        }

        .tf-btn {
            width: auto;
        }
    }
}

//list view
.list-view {
    padding: 12px;
    box-shadow: $tf_shadow;
    background: $white_color;
    border-radius: 8px;

    li {
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        font-weight: $fw_4;
        color: $secondary_color;

        &:not(:last-child) {
            border-bottom: 1px solid $line_br_color;
            margin-bottom: 8px;
        }

        span {
            color: $on_surface_color;
            font-weight: $fw_6;
        }
    }
}

.circle-box {
    display: inline-block;
    border-radius: 50%;

    &.check-icon {
        position: relative;

        &::after {
            position: absolute;
            border: 4px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            top: 22px;
            left: 22px;
            height: 13px;
            transform: rotate(-45deg);
            width: 22px;
            border-radius: 3px;
        }
    }

    &.xl {
        width: 138px;
        height: 138px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 66px;
            color: $white_color;
        }
    }

    &.lg {
        width: 82px;
        height: 82px;
        border: 10px solid $white_color;
        box-shadow: 0px 4px 20px 0px rgba(158, 158, 158, 0.25);
    }

    &.md {
        width: 24px;
        height: 24px;
        border: 3px solid $white_color;
        box-shadow: 0px 4px 20px 0px rgba(158, 158, 158, 0.25);
    }

    &.sm {
        width: 8px;
        height: 8px;
        border: 1px solid $white_color;
        box-shadow: 0px 4px 20px 0px rgba(158, 158, 158, 0.25);
    }

    &.bg-circle {
        background-color: $success_color;
    }

    &.bg-critical {
        background-color: $critical_color;
    }

    &.bg-blue {
        background-color: $primary_color;
    }
}

//bank - list
.bank-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white_color;
    gap: 4px;
    border-radius: 8px;
    padding: 12px 0px;
    cursor: pointer;
    position: relative;
    box-shadow: $tf_shadow;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: $on_surface_color;

    .logo-bank {
        width: 32px;
        height: 32px;
    }
}

.app-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.list-money {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;

    .tag-money {
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 12px 32px;
        background: $surface_color;
        border-radius: 8px;
    }
}

// card - list
.tf-card-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .logo {
        width: 48px;
        height: 32px;
    }

    .info {
        flex: 1;
    }

    &.large {
        padding: 16px;
    }

    &.medium {
        padding: 8px 16px;
    }

    &.out-line {
        border: 1px solid $primary_color;
        border-radius: 8px;
    }

    &.bt-line {
        border-bottom: 1px solid $line_br_color;
    }
}

.tf-checkbox {
    &.circle-check {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $secondary_color;

        &:checked {
            background-color: $success_color;
            border: 1px solid $success_color;
        }

        &::before {
            right: 40%;
            top: 46%;
            width: 6px;
            height: 10px;
            margin: 0;
            border-width: 0px 3px 3px 0px;
        }
    }
}

.auth-line {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 1px;
        width: 40%;
        border: 1px solid $line_br_color;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 40%;
        border: 1px solid $line_br_color;
    }
}

// atm
.tf-atm-card {
    background: url(../../images/banner/banner-card.png);
    padding: 19px 19px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    border-radius: 16px;

    .heading {
        display: flex;
        justify-content: space-between;

        a {
            i {
                color: $white_color;
                font-size: 16px;
            }
        }
    }

    h3 {
        padding: 42px 0px 12px;
    }

    .bottom {
        background: $on_surface_color;
        margin: 0px -19px;
        padding: 4px 19px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        span {
            font-size: 10px;
            line-height: 16px;
        }

        a {
            color: $white_color;
            font-size: 14px;
            line-height: 22px;
            font-weight: $fw_6;
        }
    }
}

.list-user-info {
    padding: 11px 0px;
    border-bottom: 1px solid $line_br_color;
    font-size: 14px;
    color: $on_surface_color;
    line-height: 22px;
    gap: 12px;
    display: flex;
    align-items: center;

    span {
        font-size: 20px;
    }
}

.noti-list {
    padding-bottom: 8px;
    border-bottom: 1px solid $line_br_color;
    display: flex;
    gap: 8px;
    margin-bottom: 12px;

    .icon-box {
        width: 32px;
        height: 32px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-right {
        flex: 1;

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                font-size: 10px;
                line-height: 16px;
            }
        }

        .desc {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dot {
                padding: 4px;
                display: inline-block;
                border-radius: 1000px;
                background: $critical_color;
            }
        }
    }
}

.preload {
    overflow: hidden;
}

.preload-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: $primary_color;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999999999;
    display: block;
    overflow: hidden;
}

.preload-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 96px;
    height: 96px;
    z-index: 100;
    margin: -45px 0 0 -45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary_color;
    background-image: url(../../images/icons/icon-96x96.png);
    background-repeat: no-repeat;
    background-position: center center;
    animation: preload 1s linear infinite alternate;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

/* animation */
.ani1 {
    -webkit-animation: ani1 10s infinite ease-in-out alternate;
    animation: ani1 10s infinite ease-in-out alternate;
}

.ani2 {
    -webkit-animation: ani2 10s infinite ease-in-out alternate;
    animation: ani2 10s infinite ease-in-out alternate;
}

.ani3 {
    -webkit-animation: ani3 4s infinite ease-in-out alternate;
    animation: ani3 4s infinite ease-in-out alternate;
}

.ani4 {
    -webkit-animation: ani4 7s infinite ease-in-out alternate;
    animation: ani4 7s infinite ease-in-out alternate;
}

.ani5 {
    -webkit-animation: ani5 7s infinite ease-in-out alternate;
    animation: ani5 7s infinite ease-in-out alternate;
}

.ani6 {
    -webkit-animation: ani6 7s infinite ease-in-out alternate;
    animation: ani6 7s infinite ease-in-out alternate;
}

.ani7 {
    -webkit-animation: ani7 7s infinite ease-in-out alternate;
    animation: ani7 7s infinite ease-in-out alternate;
}

.ani8 {
    -webkit-animation: ani8 2000ms infinite ease-in-out alternate;
    animation: ani8 2000ms infinite ease-in-out alternate;
}

@keyframes ani1 {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(-73px, 1px) rotate(-36deg);
        transform: translate(-73px, 1px) rotate(-36deg);
    }

    40% {
        -webkit-transform: translate(-141px, -72px) rotate(-72deg);
        transform: translate(-141px, -72px) rotate(-72deg);
    }

    60% {
        -webkit-transform: translate(-83px, -122px) rotate(-108deg);
        transform: translate(-83px, -122px) rotate(-108deg);
    }

    80% {
        -webkit-transform: translate(40px, -72px) rotate(-144deg);
        transform: translate(40px, -72px) rotate(-144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes ani2 {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(20px, 1px) rotate(36deg);
        transform: translate(20px, 1px) rotate(36deg);
    }

    40% {
        -webkit-transform: translate(100px, 72px) rotate(72deg);
        transform: translate(100px, 72px) rotate(72deg);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(40px, 72px) rotate(144deg);
        transform: translate(40px, 72px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes ani3 {
    0% {
        transform: scale(0.8, 0.8);
    }

    50% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(0.8, 0.8);
    }
}

@keyframes ani4 {
    0%,
    100% {
        transform: translateX(0);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    50% {
        transform: translateX(-20px);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}

@keyframes ani5 {
    0%,
    100% {
        transform: translateX(0);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    50% {
        transform: translateX(20px);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}

@keyframes ani6 {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(-13px, 1px) rotate(-6deg);
        transform: translate(-13px, 1px) rotate(-6deg);
    }

    40% {
        -webkit-transform: translate(-41px, -22px) rotate(-22deg);
        transform: translate(-41px, -22px) rotate(-22deg);
    }

    60% {
        -webkit-transform: translate(-33px, -22px) rotate(-50deg);
        transform: translate(-33px, -22px) rotate(-50deg);
    }

    80% {
        -webkit-transform: translate(40px, -72px) rotate(-50deg);
        transform: translate(40px, -72px) rotate(-50deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes ani7 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes ani8 {
    from {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        -webkit-transform: translateY(-7px);
        -moz-transform: translateY(-7px);
        -o-transform: translateY(-7px);
        -ms-transform: translateY(-7px);
        transform: translateY(-7px);
    }
}
