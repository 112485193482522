[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bankgroup:before {
  content: "\eafd";
  color: #000;
}

.icon-vector-star:before {
  content: "\eb69";
}

.icon-camera:before {
  content: "\e906";
  color: #fff;
}

.icon-driver .path1:before {
  content: "\e907";
  color: rgb(197, 197, 197);
}

.icon-driver .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(197, 197, 197);
}

.icon-driver .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(30, 30, 30);
}

.icon-star:before {
  content: "\e90a";
  color: #f2c71c;
}

.icon-send4:before {
  content: "\e90b";
  color: #fff;
}

.icon-my-qr .path1:before {
  content: "\e90d";
  color: rgb(113, 113, 113);
}

.icon-my-qr .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(113, 113, 113);
}

.icon-my-qr .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(113, 113, 113);
}

.icon-my-qr .path4:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(83, 61, 234);
}

.icon-my-qr .path5:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(113, 113, 113);
}

.icon-my-qr .path6:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(113, 113, 113);
}

.icon-my-qr .path7:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(113, 113, 113);
}

.icon-group-transfers .path1:before {
  content: "\e914";
  color: rgb(113, 113, 113);
}

.icon-group-transfers .path2:before {
  content: "\e915";
  margin-left: -0.8662109375em;
  color: rgb(113, 113, 113);
}

.icon-group-transfers .path3:before {
  content: "\e916";
  margin-left: -0.8662109375em;
  color: rgb(83, 61, 234);
}

.icon-group-credit-card .path1:before {
  content: "\e917";
  color: rgb(113, 113, 113);
}

.icon-group-credit-card .path2:before {
  content: "\e918";
  margin-left: -1.025390625em;
  color: rgb(113, 113, 113);
}

.icon-group-credit-card .path3:before {
  content: "\e919";
  margin-left: -1.025390625em;
  color: rgb(83, 61, 234);
}

.icon-topup .path1:before {
  content: "\e91a";
  color: rgb(113, 113, 113);
}

.icon-topup .path2:before {
  content: "\e91b";
  margin-left: -1.025390625em;
  color: rgb(113, 113, 113);
}

.icon-topup .path3:before {
  content: "\e91c";
  margin-left: -1.025390625em;
  color: rgb(255, 255, 255);
}

.icon-topup .path4:before {
  content: "\e91d";
  margin-left: -1.025390625em;
  color: rgb(83, 61, 234);
}

.icon-group-dollar:before {
  content: "\e91e";
  color: #f2c71c;
}

.icon-setting1:before {
  content: "\e95d";
}

.icon-user3:before {
  content: "\e9a7";
}

.icon-mail:before {
  content: "\e929";
}

.icon-phone:before {
  content: "\e9aa";
}

.icon-wlet:before {
  content: "\eb6f";
}

.icon-user:before {
  content: "\eb70";
}

.icon-eye:before {
  content: "\eb6a";
  color: #c5c5c5;
}

.icon-apple:before {
  content: "\e91f";
}

.icon-google .path1:before {
  content: "\e920";
  color: rgb(22, 126, 230);
}

.icon-google .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(18, 179, 71);
}

.icon-google .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(15, 153, 62);
}

.icon-google .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(255, 213, 0);
}

.icon-google .path5:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(255, 75, 38);
}

.icon-google .path6:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(217, 63, 33);
}

.icon-facebook .path1:before {
  content: "\e926";
  color: rgb(24, 119, 242);
}

.icon-facebook .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-location:before {
  content: "\e928";
  color: #717171;
}

.icon-bil .path1:before {
  content: "\e92a";
  color: rgb(255, 206, 0);
}

.icon-bil .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(255, 206, 0);
}

.icon-bil .path3:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-bil .path4:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-bil .path5:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(99, 105, 120);
}

.icon-bil .path6:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(99, 105, 120);
}

.icon-bil .path7:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(99, 105, 120);
}

.icon-bil .path8:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 108, 82);
}

.icon-bil .path9:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(99, 105, 120);
}

.icon-bil .path10:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(99, 105, 120);
}

.icon-bil .path11:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(65, 73, 82);
}

.icon-term .path1:before {
  content: "\e935";
  color: rgb(56, 43, 115);
}

.icon-term .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(71, 48, 128);
}

.icon-term .path3:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(56, 43, 115);
}

.icon-term .path4:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(209, 211, 212);
}

.icon-term .path5:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-term .path6:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(209, 211, 212);
}

.icon-term .path7:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(28, 174, 228);
}

.icon-term .path8:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(0, 154, 221);
}

.icon-term .path9:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(19, 109, 160);
}

.icon-term .path10:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(19, 109, 160);
}

.icon-term .path11:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-term .path12:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(209, 211, 212);
}

.icon-term .path13:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-term .path14:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(209, 211, 212);
}

.icon-term .path15:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path16:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path17:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path18:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path19:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path20:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(28, 174, 228);
}

.icon-term .path21:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path22:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path23:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path24:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path25:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path26:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}

.icon-term .path27:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(231, 173, 39);
}

.icon-term .path28:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(254, 222, 58);
}

.icon-term .path29:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(247, 203, 21);
}

.icon-term .path30:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(231, 173, 39);
}

.icon-term .path31:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(231, 173, 39);
}

.icon-term .path32:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(209, 211, 212);
}

.icon-term .path33:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-bill .path1:before {
  content: "\e956";
  color: rgb(0, 0, 0);
}

.icon-bill .path2:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(244, 81, 81);
}

.icon-bill .path3:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-bill .path4:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-send2:before {
  content: "\e95a";
}

.icon-arrow-up_minor:before {
  content: "\e95b";
  color: #000;
}

.icon-wallet-filled-money-tool:before {
  content: "\e95c";
}

.icon-bank2:before {
  content: "\eb68";
}

.icon-friends:before {
  content: "\e95e";
}

.icon-filter:before {
  content: "\e9a2";
}

.icon-manage .path1:before {
  content: "\e960";
  color: rgb(89, 108, 128);
}

.icon-manage .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(242, 242, 242);
}

.icon-manage .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(174, 206, 248);
}

.icon-manage .path4:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(242, 242, 242);
}

.icon-manage .path5:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(255, 187, 51);
}

.icon-manage .path6:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(41, 112, 204);
}

.icon-manage .path7:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(217, 87, 93);
}

.icon-manage .path8:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(62, 153, 68);
}

.icon-manage .path9:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(71, 86, 102);
}

.icon-statistics .path1:before {
  content: "\e969";
  color: rgb(250, 190, 44);
}

.icon-statistics .path2:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(106, 169, 255);
}

.icon-statistics .path3:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 67, 91);
}

.icon-statistics .path4:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(254, 216, 67);
}

.icon-statistics .path5:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 123, 74);
}

.icon-statistics .path6:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(255, 67, 91);
}

.icon-statistics .path7:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(126, 216, 246);
}

.icon-statistics .path8:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(97, 114, 155);
}

.icon-secure1:before {
  content: "\e971";
}

.icon-loanpayment .path1:before {
  content: "\e972";
  color: rgb(149, 76, 53);
}

.icon-loanpayment .path2:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(149, 76, 53);
}

.icon-loanpayment .path3:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(32, 183, 161);
}

.icon-loanpayment .path4:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(36, 211, 181);
}

.icon-loanpayment .path5:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(255, 222, 145);
}

.icon-loanpayment .path6:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}

.icon-loanpayment .path7:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(255, 222, 145);
}

.icon-loanpayment .path8:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(255, 232, 156);
}

.icon-loanpayment .path9:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(219, 173, 92);
}

.icon-loanpayment .path10:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}

.icon-loanpayment .path11:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(255, 232, 156);
}

.icon-loanpayment .path12:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(255, 232, 156);
}

.icon-loanpayment .path13:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(252, 242, 182);
}

.icon-loanpayment .path14:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(219, 173, 92);
}

.icon-loanpayment .path15:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}

.icon-loanpayment .path16:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(255, 232, 156);
}

.icon-loanpayment .path17:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(192, 105, 78);
}

.icon-loanpayment .path18:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(201, 126, 103);
}

.icon-loanpayment .path19:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(255, 222, 145);
}

.icon-loanpayment .path20:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(255, 222, 145);
}

.icon-loanpayment .path21:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(255, 222, 145);
}

.icon-loanpayment .path22:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(255, 222, 145);
}

.icon-loanpayment .path23:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}

.icon-environment .path1:before {
  content: "\e989";
  color: rgb(199, 174, 113);
}

.icon-environment .path2:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(126, 180, 87);
}

.icon-environment .path3:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(126, 180, 87);
}

.icon-environment .path4:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(208, 210, 75);
}

.icon-environment .path5:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(95, 149, 72);
}

.icon-environment .path6:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(95, 149, 72);
}

.icon-environment .path7:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(95, 149, 72);
}

.icon-environment .path8:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(126, 180, 87);
}

.icon-environment .path9:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(208, 210, 75);
}

.icon-environment .path10:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(126, 180, 87);
}

.icon-environment .path11:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(208, 210, 75);
}

.icon-noti:before {
  content: "\eb6e";
}

.icon-check:before {
  content: "\eaed";
}

.icon-water2:before {
  content: "\e994";
  color: #438df7;
}

.icon-light .path1:before {
  content: "\e995";
  color: rgb(255, 209, 92);
}

.icon-light .path2:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-light .path3:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(52, 74, 94);
}

.icon-light .path4:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(52, 74, 94);
}

.icon-light .path5:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(65, 90, 107);
}

.icon-light .path6:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(65, 90, 107);
}

.icon-light .path7:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-light .path8:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-light .path9:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-light .path10:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-light .path11:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-light .path12:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-light .path13:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-home2:before {
  content: "\e9a3";
  color: rgb(255, 209, 92);
  font-size: 20px;
}

.icon-path49:before {
  content: "\e9a4";
}

.icon-union:before {
  content: "\e9a5";
}

.icon-clear:before {
  content: "\e9a6";
}

.icon-visa:before {
  content: "\e95f";
}

.icon-info:before {
  content: "\e9a8";
}

.icon-calendar2:before {
  content: "\e9a9";
}

.icon-email:before {
  content: "\e9ab";
}

.icon-copy2:before {
  content: "\e9ac";
}

.icon-request:before {
  content: "\e9ad";
}

.icon-send:before {
  content: "\e9ae";
}

.icon-calendar:before {
  content: "\e9af";
}

.icon-camera-to-take-photos:before {
  content: "\e9b0";
}

.icon-recycle-bin:before {
  content: "\e9b1";
}

.icon-more1:before {
  content: "\e9b2";
}

.icon-edit:before {
  content: "\e9b3";
}

.icon-plus:before {
  content: "\e9b4";
}

.icon-travel .path1:before {
  content: "\e9b5";
  color: rgb(149, 165, 165);
}

.icon-travel .path2:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(149, 165, 165);
}

.icon-travel .path3:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(53, 73, 94);
}

.icon-travel .path4:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(53, 73, 94);
}

.icon-travel .path5:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(63, 92, 108);
}

.icon-travel .path6:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(41, 128, 186);
}

.icon-travel .path7:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(59, 151, 211);
}

.icon-travel .path8:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(59, 151, 211);
}

.icon-travel .path9:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(59, 151, 211);
}

.icon-travel .path10:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(59, 151, 211);
}

.icon-travel .path11:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(53, 73, 94);
}

.icon-travel .path12:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(149, 165, 165);
}

.icon-travel .path13:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(229, 126, 37);
}

.icon-travel .path14:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(63, 92, 108);
}

.icon-travel .path15:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(242, 156, 31);
}

.icon-travel .path16:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(128, 47, 52);
}

.icon-travel .path17:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(192, 58, 43);
}

.icon-travel .path18:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(165, 106, 67);
}

.icon-travel .path19:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(128, 83, 51);
}

.icon-travel .path20:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-travel .path21:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-travel .path22:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-travel .path23:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-travel .path24:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-travel .path25:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(189, 195, 199);
}

.icon-travel .path26:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(189, 195, 199);
}

.icon-vouucher .path1:before {
  content: "\e9cf";
  color: rgb(223, 100, 110);
}

.icon-vouucher .path2:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(223, 100, 110);
}

.icon-vouucher .path3:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(220, 73, 85);
}

.icon-vouucher .path4:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(220, 73, 85);
}

.icon-vouucher .path5:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(220, 73, 85);
}

.icon-vouucher .path6:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(249, 175, 56);
}

.icon-vouucher .path7:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(244, 137, 49);
}

.icon-vouucher .path8:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(244, 137, 49);
}

.icon-vouucher .path9:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(244, 137, 49);
}

.icon-vouucher .path10:before {
  content: "\e9d8";
  margin-left: -1em;
  color: rgb(244, 137, 49);
}

.icon-vouucher .path11:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(244, 137, 49);
}

.icon-vouucher .path12:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(244, 137, 49);
}

.icon-vouucher .path13:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(223, 100, 110);
}

.icon-vouucher .path14:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(223, 100, 110);
}

.icon-vouucher .path15:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(223, 100, 110);
}

.icon-coffee .path1:before {
  content: "\e9de";
  color: rgb(192, 58, 43);
}

.icon-coffee .path2:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(189, 195, 199);
}

.icon-coffee .path3:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(230, 76, 60);
}

.icon-coffee .path4:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(189, 195, 199);
}

.icon-coffee .path5:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(165, 106, 67);
}

.icon-coffee .path6:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(242, 156, 31);
}

.icon-coffee .path7:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(229, 126, 37);
}

.icon-coffee .path8:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(165, 106, 67);
}

.icon-coffee .path9:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(236, 240, 241);
}

.icon-coffee .path10:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(165, 106, 67);
}

.icon-coffee .path11:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(128, 83, 51);
}

.icon-gift-box .path1:before {
  content: "\e9e9";
  color: rgb(252, 26, 64);
}

.icon-gift-box .path2:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(198, 0, 52);
}

.icon-gift-box .path3:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(254, 153, 35);
}

.icon-gift-box .path4:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(254, 136, 33);
}

.icon-gift-box .path5:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(255, 62, 117);
}

.icon-gift-box .path6:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(252, 26, 64);
}

.icon-gift-box .path7:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(252, 191, 41);
}

.icon-gift-box .path8:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(252, 191, 41);
}

.icon-gift-box .path9:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(254, 153, 35);
}

.icon-gift-box .path10:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(254, 153, 35);
}

.icon-cosmetic .path1:before {
  content: "\e9f3";
  color: rgb(222, 74, 140);
}

.icon-cosmetic .path2:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(242, 109, 166);
}

.icon-cosmetic .path3:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(246, 204, 175);
}

.icon-cosmetic .path4:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(255, 222, 199);
}

.icon-cosmetic .path5:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(55, 79, 104);
}

.icon-cosmetic .path6:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(66, 91, 114);
}

.icon-cosmetic .path7:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(55, 79, 104);
}

.icon-cosmetic .path8:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(66, 91, 114);
}

.icon-cosmetic .path9:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(55, 79, 104);
}

.icon-cosmetic .path10:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(66, 91, 114);
}

.icon-cosmetic .path11:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(219, 86, 105);
}

.icon-cosmetic .path12:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(242, 102, 116);
}

.icon-cosmetic .path13:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(159, 219, 243);
}

.icon-cosmetic .path14:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(178, 229, 251);
}

.icon-cosmetic .path15:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(219, 86, 105);
}

.icon-cosmetic .path16:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(242, 102, 116);
}

.icon-cosmetic .path17:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(222, 74, 140);
}

.icon-cosmetic .path18:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(242, 109, 166);
}

.icon-cosmetic .path19:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(222, 74, 140);
}

.icon-cosmetic .path20:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(242, 109, 166);
}

.icon-medical .path1:before {
  content: "\ea07";
  color: rgb(109, 109, 109);
}

.icon-medical .path2:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}

.icon-medical .path3:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}

.icon-medical .path4:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(234, 78, 78);
}

.icon-medical .path5:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgb(234, 78, 78);
}

.icon-medical .path6:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(249, 100, 100);
}

.icon-medical .path7:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}

.icon-medical .path8:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.08;
}

.icon-medical .path9:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(242, 240, 240);
}

.icon-medical .path10:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(249, 100, 100);
}

.icon-grocery-store .path1:before {
  content: "\ea11";
  color: rgb(0, 109, 240);
}

.icon-grocery-store .path2:before {
  content: "\ea12";
  margin-left: -1em;
  color: rgb(0, 94, 206);
}

.icon-grocery-store .path3:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(255, 212, 34);
}

.icon-grocery-store .path4:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(255, 212, 34);
}

.icon-grocery-store .path5:before {
  content: "\ea15";
  margin-left: -1em;
  color: rgb(189, 0, 34);
}

.icon-grocery-store .path6:before {
  content: "\ea16";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}

.icon-grocery-store .path7:before {
  content: "\ea17";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}

.icon-grocery-store .path8:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(255, 212, 34);
}

.icon-grocery-store .path9:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(189, 0, 34);
}

.icon-grocery-store .path10:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}

.icon-grocery-store .path11:before {
  content: "\ea1b";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}

.icon-grocery-store .path12:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(235, 191, 0);
}

.icon-grocery-store .path13:before {
  content: "\ea1d";
  margin-left: -1em;
  color: rgb(189, 0, 34);
}

.icon-grocery-store .path14:before {
  content: "\ea1e";
  margin-left: -1em;
  color: rgb(235, 191, 0);
}

.icon-grocery-store .path15:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(189, 0, 34);
}

.icon-grocery-store .path16:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(235, 191, 0);
}

.icon-grocery-store .path17:before {
  content: "\ea21";
  margin-left: -1em;
  color: rgb(235, 191, 0);
}

.icon-grocery-store .path18:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(255, 205, 0);
}

.icon-grocery-store .path19:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(235, 191, 0);
}

.icon-grocery-store .path20:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(221, 178, 0);
}

.icon-grocery-store .path21:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(126, 214, 62);
}

.icon-grocery-store .path22:before {
  content: "\ea26";
  margin-left: -1em;
  color: rgb(110, 187, 54);
}

.icon-grocery-store .path23:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(0, 109, 240);
}

.icon-grocery-store .path24:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(88, 89, 91);
}

.icon-grocery-store .path25:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(0, 94, 206);
}

.icon-grocery-store .path26:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(109, 110, 113);
}

.icon-grocery-store .path27:before {
  content: "\ea2b";
  margin-left: -1em;
  color: rgb(109, 110, 113);
}

.icon-grocery-store .path28:before {
  content: "\ea2c";
  margin-left: -1em;
  color: rgb(241, 242, 242);
}

.icon-grocery-store .path29:before {
  content: "\ea2d";
  margin-left: -1em;
  color: rgb(241, 242, 242);
}

.icon-grocery-store .path30:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(241, 242, 242);
}

.icon-grocery-store .path31:before {
  content: "\ea2f";
  margin-left: -1em;
  color: rgb(241, 242, 242);
}

.icon-restaurant .path1:before {
  content: "\ea30";
  color: rgb(247, 178, 57);
}

.icon-restaurant .path2:before {
  content: "\ea31";
  margin-left: -1em;
  color: rgb(224, 155, 45);
}

.icon-restaurant .path3:before {
  content: "\ea32";
  margin-left: -1em;
  color: rgb(242, 76, 143);
}

.icon-restaurant .path4:before {
  content: "\ea33";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}

.icon-restaurant .path5:before {
  content: "\ea34";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}

.icon-restaurant .path6:before {
  content: "\ea35";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}

.icon-restaurant .path7:before {
  content: "\ea36";
  margin-left: -1em;
  color: rgb(242, 76, 143);
}

.icon-restaurant .path8:before {
  content: "\ea37";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}

.icon-milktea .path1:before {
  content: "\ea38";
  color: rgb(255, 211, 4);
}

.icon-milktea .path2:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(155, 216, 249);
}

.icon-milktea .path3:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(252, 172, 184);
}

.icon-milktea .path4:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(255, 113, 134);
}

.icon-milktea .path5:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(255, 65, 85);
}

.icon-milktea .path6:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(228, 229, 234);
}

.icon-milktea .path7:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(228, 229, 234);
}

.icon-milktea .path8:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(115, 195, 249);
}

.icon-milktea .path9:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(84, 62, 91);
}

.icon-milktea .path10:before {
  content: "\ea41";
  margin-left: -1em;
  color: rgb(84, 62, 91);
}

.icon-milktea .path11:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(84, 62, 91);
}

.icon-milktea .path12:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(84, 62, 91);
}

.icon-milktea .path13:before {
  content: "\ea44";
  margin-left: -1em;
  color: rgb(84, 62, 91);
}

.icon-milktea .path14:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(108, 84, 117);
}

.icon-milktea .path15:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(119, 67, 66);
}

.icon-milktea .path16:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(175, 119, 116);
}

.icon-milktea .path17:before {
  content: "\ea48";
  margin-left: -1em;
  color: rgb(140, 87, 84);
}

.icon-milktea .path18:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(140, 87, 84);
}

.icon-milktea .path19:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(140, 87, 84);
}

.icon-milktea .path20:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(228, 229, 234);
}

.icon-milktea .path21:before {
  content: "\ea4c";
  margin-left: -1em;
  color: rgb(247, 247, 247);
}

.icon-milktea .path22:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(210, 211, 222);
}

.icon-milktea .path23:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(255, 233, 143);
}

.icon-milktea .path24:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(255, 233, 143);
}

.icon-milktea .path25:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(247, 247, 247);
}

.icon-milktea .path26:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(108, 84, 117);
}

.icon-milktea .path27:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(108, 84, 117);
}

.icon-milktea .path28:before {
  content: "\ea53";
  margin-left: -1em;
  color: rgb(108, 84, 117);
}

.icon-milktea .path29:before {
  content: "\ea54";
  margin-left: -1em;
  color: rgb(108, 84, 117);
}

.icon-milktea .path30:before {
  content: "\ea55";
  margin-left: -1em;
  color: rgb(153, 130, 163);
}

.icon-milktea .path31:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(108, 84, 117);
}

.icon-more:before {
  content: "\ea57";
}

.icon-add-card:before {
  content: "\ea58";
}

.icon-back:before {
  content: "\ea59";
}

.icon-qr-code3 .path1:before {
  content: "\ea5a";
  color: rgb(83, 61, 234);
}

.icon-qr-code3 .path2:before {
  content: "\ea5b";
  margin-left: -1em;
  color: rgb(83, 61, 234);
}

.icon-qr-code3 .path3:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(102, 90, 240);
}

.icon-qr-code3 .path4:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(102, 90, 240);
}

.icon-qr-code3 .path5:before {
  content: "\ea5e";
  margin-left: -1em;
  color: rgb(102, 90, 240);
}

.icon-qr-code3 .path6:before {
  content: "\ea5f";
  margin-left: -1em;
  color: rgb(83, 61, 234);
}

.icon-qr-code3 .path7:before {
  content: "\ea60";
  margin-left: -1em;
  color: rgb(102, 90, 240);
}

.icon-qr-code3 .path8:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(102, 90, 240);
}

.icon-qr-code3 .path9:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(102, 90, 240);
}

.icon-scan .path1:before {
  content: "\ea63";
  color: rgb(102, 90, 240);
}

.icon-scan .path2:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(250, 190, 60);
}

.icon-wallet2 .path1:before {
  content: "\ea65";
  color: rgb(254, 188, 17);
}

.icon-wallet2 .path2:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(254, 188, 17);
}

.icon-wallet2 .path3:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(102, 90, 240);
}

.icon-wallet2 .path4:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(102, 90, 240);
}

.icon-money .path1:before {
  content: "\ea69";
  color: rgb(102, 90, 240);
}

.icon-money .path2:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(254, 188, 17);
}

.icon-invoice .path1:before {
  content: "\ea6b";
  color: rgb(218, 155, 0);
}

.icon-invoice .path2:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(254, 204, 14);
}

.icon-invoice .path3:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-invoice .path4:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-invoice .path5:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-invoice .path6:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-coin:before {
  content: "\e902";
}

.icon-coupon1 .path1:before {
  content: "\e903";
  color: rgb(247, 195, 49);
}

.icon-coupon1 .path2:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(229, 53, 75);
}

.icon-coupon1 .path3:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(193, 39, 72);
}

.icon-coupon1 .path4:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(193, 39, 72);
}

.icon-coupon1 .path5:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(193, 39, 72);
}

.icon-coupon1 .path6:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(193, 39, 72);
}

.icon-coupon1 .path7:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(193, 39, 72);
}

.icon-coupon1 .path8:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(193, 39, 72);
}

.icon-coupon1 .path9:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(193, 39, 72);
}

.icon-coupon1 .path10:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-coupon1 .path11:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-coupon1 .path12:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-credit-card2:before {
  content: "\ea7c";
}

.icon-scan-qr-code:before {
  content: "\ea7d";
}

.icon-up:before {
  content: "\ea7e";
}

.icon-right:before {
  content: "\ea7f";
}

.icon-down:before {
  content: "\ea80";
}

.icon-left:before {
  content: "\ea81";
  font-size: 24px;
}

.icon-health-care .path1:before {
  content: "\ea82";
  color: rgb(229, 99, 83);
}

.icon-health-care .path2:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-health-care .path3:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(252, 212, 98);
}

.icon-health-care .path4:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(246, 195, 88);
}

.icon-health-care .path5:before {
  content: "\ea86";
  margin-left: -1em;
  color: rgb(246, 195, 88);
}

.icon-health-care .path6:before {
  content: "\ea87";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path7:before {
  content: "\ea88";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path8:before {
  content: "\ea89";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path9:before {
  content: "\ea8a";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path10:before {
  content: "\ea8b";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path11:before {
  content: "\ea8c";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path12:before {
  content: "\ea8d";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path13:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path14:before {
  content: "\ea8f";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path15:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path16:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(225, 230, 233);
}

.icon-health-care .path17:before {
  content: "\ea92";
  margin-left: -1em;
  color: rgb(225, 230, 233);
}

.icon-health-care .path18:before {
  content: "\ea93";
  margin-left: -1em;
  color: rgb(246, 195, 88);
}

.icon-health-care .path19:before {
  content: "\ea94";
  margin-left: -1em;
  color: rgb(246, 195, 88);
}

.icon-health-care .path20:before {
  content: "\ea95";
  margin-left: -1em;
  color: rgb(252, 212, 98);
}

.icon-health-care .path21:before {
  content: "\ea96";
  margin-left: -1em;
  color: rgb(100, 121, 138);
}

.icon-health-care .path22:before {
  content: "\ea97";
  margin-left: -1em;
  color: rgb(100, 121, 138);
}

.icon-health-care .path23:before {
  content: "\ea98";
  margin-left: -1em;
  color: rgb(68, 196, 161);
}

.icon-health-care .path24:before {
  content: "\ea99";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-health-care .path25:before {
  content: "\ea9a";
  margin-left: -1em;
  color: rgb(229, 99, 83);
}

.icon-health-care .path26:before {
  content: "\ea9b";
  margin-left: -1em;
  color: rgb(209, 82, 65);
}

.icon-health-care .path27:before {
  content: "\ea9c";
  margin-left: -1em;
  color: rgb(62, 166, 155);
}

.icon-health-care .path28:before {
  content: "\ea9d";
  margin-left: -1em;
  color: rgb(62, 166, 155);
}

.icon-health-care .path29:before {
  content: "\ea9e";
  margin-left: -1em;
  color: rgb(62, 166, 155);
}

.icon-health-care .path30:before {
  content: "\ea9f";
  margin-left: -1em;
  color: rgb(62, 166, 155);
}

.icon-health-care .path31:before {
  content: "\eaa0";
  margin-left: -1em;
  color: rgb(62, 166, 155);
}

.icon-health-care .path32:before {
  content: "\eaa1";
  margin-left: -1em;
  color: rgb(62, 166, 155);
}

.icon-health-care .path33:before {
  content: "\eaa2";
  margin-left: -1em;
  color: rgb(229, 99, 83);
}

.icon-health-care .path34:before {
  content: "\eaa3";
  margin-left: -1em;
  color: rgb(47, 72, 89);
}

.icon-health-care .path35:before {
  content: "\eaa4";
  margin-left: -1em;
  color: rgb(225, 230, 233);
}

.icon-health-care .path36:before {
  content: "\eaa5";
  margin-left: -1em;
  color: rgb(47, 72, 89);
}

.icon-health-care .path37:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(100, 121, 138);
}

.icon-health-care .path38:before {
  content: "\eaa7";
  margin-left: -1em;
  color: rgb(100, 121, 138);
}

.icon-health-care .path39:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(235, 240, 243);
}

.icon-health-care .path40:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(213, 214, 219);
}

.icon-health-care .path41:before {
  content: "\eaaa";
  margin-left: -1em;
  color: rgb(235, 240, 243);
}

.icon-health-care .path42:before {
  content: "\eaab";
  margin-left: -1em;
  color: rgb(100, 121, 138);
}

.icon-health-care .path43:before {
  content: "\eaac";
  margin-left: -1em;
  color: rgb(235, 240, 243);
}

.icon-health-care .path44:before {
  content: "\eaad";
  margin-left: -1em;
  color: rgb(235, 240, 243);
}

.icon-health-care .path45:before {
  content: "\eaae";
  margin-left: -1em;
  color: rgb(246, 195, 88);
}

.icon-qr-code:before {
  content: "\eaaf";
}

.icon-home:before {
  content: "\e904";
  font-size: 24px;
}

.icon-star-outline:before {
  content: "\eafb";
}

.icon-star-fill:before {
  content: "\eab1";
  font-size: 24px;
}

.icon-user-outline:before {
  content: "\eab2";
  font-size: 24px;
}

.icon-user-fill:before {
  content: "\eab3";
  font-size: 24px;
}

.icon-exchange:before {
  content: "\eab4";
}

.icon-market1 .path1:before {
  content: "\eab5";
  color: rgb(90, 98, 110);
}

.icon-market1 .path2:before {
  content: "\eab6";
  margin-left: -1em;
  color: rgb(81, 87, 99);
}

.icon-market1 .path3:before {
  content: "\eab7";
  margin-left: -1em;
  color: rgb(81, 87, 99);
}

.icon-market1 .path4:before {
  content: "\eab8";
  margin-left: -1em;
  color: rgb(231, 235, 239);
}

.icon-market1 .path5:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(251, 79, 86);
}

.icon-market1 .path6:before {
  content: "\eaba";
  margin-left: -1em;
  color: rgb(252, 184, 96);
}

.icon-market1 .path7:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(231, 235, 239);
}

.icon-market1 .path8:before {
  content: "\eabc";
  margin-left: -1em;
  color: rgb(104, 177, 252);
}

.icon-market1 .path9:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(104, 177, 252);
}

.icon-market1 .path10:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(252, 184, 96);
}

.icon-market1 .path11:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(251, 176, 79);
}

.icon-market1 .path12:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(90, 98, 110);
}

.icon-market1 .path13:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(251, 176, 79);
}

.icon-market1 .path14:before {
  content: "\eac2";
  margin-left: -1em;
  color: rgb(252, 184, 96);
}

.icon-market1 .path15:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(251, 176, 79);
}

.icon-market1 .path16:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(231, 235, 239);
}

.icon-market1 .path17:before {
  content: "\eac5";
  margin-left: -1em;
  color: rgb(81, 87, 99);
}

.icon-market1 .path18:before {
  content: "\eac6";
  margin-left: -1em;
  color: rgb(79, 164, 251);
}

.icon-market1 .path19:before {
  content: "\eac7";
  margin-left: -1em;
  color: rgb(79, 164, 251);
}

.icon-market1 .path20:before {
  content: "\eac8";
  margin-left: -1em;
  color: rgb(252, 96, 103);
}

.icon-market1 .path21:before {
  content: "\eac9";
  margin-left: -1em;
  color: rgb(251, 79, 86);
}

.icon-market1 .path22:before {
  content: "\eaca";
  margin-left: -1em;
  color: rgb(231, 235, 239);
}

.icon-market1 .path23:before {
  content: "\eacb";
  margin-left: -1em;
  color: rgb(219, 225, 231);
}

.icon-market1 .path24:before {
  content: "\eacc";
  margin-left: -1em;
  color: rgb(251, 79, 86);
}

.icon-market1 .path25:before {
  content: "\eacd";
  margin-left: -1em;
  color: rgb(251, 59, 67);
}

.icon-water1 .path1:before {
  content: "\eace";
  color: rgb(43, 140, 217);
}

.icon-water1 .path2:before {
  content: "\eacf";
  margin-left: -1em;
  color: rgb(57, 163, 248);
}

.icon-mobile1 .path1:before {
  content: "\ead0";
  color: rgb(255, 255, 255);
}

.icon-mobile1 .path2:before {
  content: "\ead1";
  margin-left: -0.9580078125em;
  color: rgb(40, 62, 180);
}

.icon-mobile1 .path3:before {
  content: "\ead2";
  margin-left: -0.9580078125em;
  color: rgb(0, 0, 0);
}

.icon-mobile1 .path4:before {
  content: "\ead3";
  margin-left: -0.9580078125em;
  color: rgb(0, 0, 0);
}

.icon-mobile1 .path5:before {
  content: "\ead4";
  margin-left: -0.9580078125em;
  color: rgb(0, 0, 0);
}

.icon-mobile1 .path6:before {
  content: "\ead5";
  margin-left: -0.9580078125em;
  color: rgb(0, 0, 0);
}

.icon-mobile1 .path7:before {
  content: "\ead6";
  margin-left: -0.9580078125em;
  color: rgb(0, 0, 0);
}

.icon-film-roll1 .path1:before {
  content: "\ead7";
  color: rgb(245, 124, 4);
}

.icon-film-roll1 .path2:before {
  content: "\ead8";
  margin-left: -1em;
  color: rgb(245, 86, 0);
}

.icon-film-roll1 .path3:before {
  content: "\ead9";
  margin-left: -1em;
  color: rgb(116, 78, 193);
}

.icon-film-roll1 .path4:before {
  content: "\eada";
  margin-left: -1em;
  color: rgb(101, 59, 186);
}

.icon-film-roll1 .path5:before {
  content: "\eadb";
  margin-left: -1em;
  color: rgb(86, 40, 180);
}

.icon-film-roll1 .path6:before {
  content: "\eadc";
  margin-left: -1em;
  color: rgb(62, 10, 169);
}

.icon-film-roll1 .path7:before {
  content: "\eadd";
  margin-left: -1em;
  color: rgb(62, 10, 169);
}

.icon-film-roll1 .path8:before {
  content: "\eade";
  margin-left: -1em;
  color: rgb(62, 10, 169);
}

.icon-film-roll1 .path9:before {
  content: "\eadf";
  margin-left: -1em;
  color: rgb(62, 10, 169);
}

.icon-film-roll1 .path10:before {
  content: "\eae0";
  margin-left: -1em;
  color: rgb(62, 10, 169);
}

.icon-film-roll1 .path11:before {
  content: "\eae1";
  margin-left: -1em;
  color: rgb(62, 10, 169);
}

.icon-film-roll1 .path12:before {
  content: "\eae2";
  margin-left: -1em;
  color: rgb(86, 40, 180);
}

.icon-film-roll1 .path13:before {
  content: "\eae3";
  margin-left: -1em;
  color: rgb(247, 178, 54);
}

.icon-film-roll1 .path14:before {
  content: "\eae4";
  margin-left: -1em;
  color: rgb(249, 193, 93);
}

.icon-film-roll1 .path15:before {
  content: "\eae5";
  margin-left: -1em;
  color: rgb(245, 149, 0);
}

.icon-film-roll1 .path16:before {
  content: "\eae6";
  margin-left: -1em;
  color: rgb(246, 153, 0);
}

.icon-history2 .path1:before {
  content: "\e905";
  color: rgb(30, 30, 30);
}

.icon-history2 .path2:before {
  content: "\eae7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-close1:before {
  content: "\eae8";
}

.icon-search:before {
  content: "\eae9";
}

.icon-steam:before {
  content: "\eaea";
}

.icon-app-store:before {
  content: "\eaeb";
}

.icon-log-in1:before {
  content: "\eaec";
}

.icon-bar-chart2:before {
  content: "\eafa";
}

.icon-elec .path1:before {
  content: "\eaee";
  color: rgb(30, 30, 30);
}

.icon-elec .path2:before {
  content: "\eaef";
  margin-left: -1em;
  color: rgb(177, 89, 89);
}

.icon-wallet1:before {
  content: "\eaf0";
}

.icon-qr-code-2:before {
  content: "\eaf1";
}

.icon-copy1:before {
  content: "\eaf2";
  color: #717171;
}

.icon-sign-in1:before {
  content: "\eaf3";
}

.icon-mobile:before {
  content: "\eaf4";
}

.icon-movie:before {
  content: "\eaf5";
}

.icon-qr-code1:before {
  content: "\eaf6";
}

.icon-credit-card1:before {
  content: "\eaf7";
}

.icon-credit-card:before {
  content: "\eaf8";
}

.icon-notification1:before {
  content: "\eaf9";
}

.icon-notification .path1:before {
  content: "\eafc";
  color: rgb(30, 30, 30);
}

.icon-notification .path2:before {
  content: "\eb6b";
  margin-left: -1em;
  color: rgb(30, 30, 30);
}

.icon-notification .path3:before {
  content: "\eb6c";
  margin-left: -1em;
  color: rgb(234, 52, 52);
}

.icon-notification .path4:before {
  content: "\eb6d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-bills-1:before {
  content: "\eafe";
}

.icon-electricity-1:before {
  content: "\eaff";
}

.icon-water:before {
  content: "\eb00";
}

.icon-market:before {
  content: "\eb01";
}

.icon-bar-chart-1:before {
  content: "\eb02";
}

.icon-wifi:before {
  content: "\e90c";
}

.icon-add-money:before {
  content: "\eb03";
}

.icon-area:before {
  content: "\eb04";
}

.icon-gifr:before {
  content: "\eb05";
  color: #000;
}

.icon-bank .path1:before {
  content: "\eb06";
  color: rgb(228, 232, 235);
}

.icon-bank .path2:before {
  content: "\eb07";
  margin-left: -1em;
  color: rgb(202, 208, 215);
}

.icon-bank .path3:before {
  content: "\eb08";
  margin-left: -1em;
  color: rgb(228, 232, 235);
}

.icon-bank .path4:before {
  content: "\eb09";
  margin-left: -1em;
  color: rgb(202, 208, 215);
}

.icon-bank .path5:before {
  content: "\eb0a";
  margin-left: -1em;
  color: rgb(253, 205, 86);
}

.icon-bank .path6:before {
  content: "\eb0b";
  margin-left: -1em;
  color: rgb(99, 108, 119);
}

.icon-bank .path7:before {
  content: "\eb0c";
  margin-left: -1em;
  color: rgb(228, 232, 235);
}

.icon-bank .path8:before {
  content: "\eb0d";
  margin-left: -1em;
  color: rgb(202, 208, 215);
}

.icon-bank .path9:before {
  content: "\eb0e";
  margin-left: -1em;
  color: rgb(99, 108, 119);
}

.icon-bank .path10:before {
  content: "\eb0f";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path11:before {
  content: "\eb10";
  margin-left: -1em;
  color: rgb(247, 108, 130);
}

.icon-bank .path12:before {
  content: "\eb11";
  margin-left: -1em;
  color: rgb(228, 232, 235);
}

.icon-bank .path13:before {
  content: "\eb12";
  margin-left: -1em;
  color: rgb(202, 208, 215);
}

.icon-bank .path14:before {
  content: "\eb13";
  margin-left: -1em;
  color: rgb(99, 108, 119);
}

.icon-bank .path15:before {
  content: "\eb14";
  margin-left: -1em;
  color: rgb(228, 232, 235);
}

.icon-bank .path16:before {
  content: "\eb15";
  margin-left: -1em;
  color: rgb(202, 208, 215);
}

.icon-bank .path17:before {
  content: "\eb16";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path18:before {
  content: "\eb17";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path19:before {
  content: "\eb18";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-bank .path20:before {
  content: "\eb19";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path21:before {
  content: "\eb1a";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path22:before {
  content: "\eb1b";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path23:before {
  content: "\eb1c";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path24:before {
  content: "\eb1d";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path25:before {
  content: "\eb1e";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path26:before {
  content: "\eb1f";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-bank .path27:before {
  content: "\eb20";
  margin-left: -1em;
  color: rgb(66, 72, 82);
}

.icon-transfers .path1:before {
  content: "\eb21";
  color: rgb(11, 213, 221);
}

.icon-transfers .path2:before {
  content: "\eb22";
  margin-left: -1em;
  color: rgb(0, 229, 238);
}

.icon-transfers .path3:before {
  content: "\eb23";
  margin-left: -1em;
  color: rgb(13, 209, 65);
}

.icon-transfers .path4:before {
  content: "\eb24";
  margin-left: -1em;
  color: rgb(82, 225, 97);
}

.icon-transfers .path5:before {
  content: "\eb25";
  margin-left: -1em;
  color: rgb(252, 116, 25);
}

.icon-transfers .path6:before {
  content: "\eb26";
  margin-left: -1em;
  color: rgb(253, 161, 2);
}

.icon-transfers .path7:before {
  content: "\eb27";
  margin-left: -1em;
  color: rgb(253, 174, 2);
}

.icon-transfers .path8:before {
  content: "\eb28";
  margin-left: -1em;
  color: rgb(253, 203, 2);
}

.icon-transfers .path9:before {
  content: "\eb29";
  margin-left: -1em;
  color: rgb(253, 213, 53);
}

.icon-transfers .path10:before {
  content: "\eb2a";
  margin-left: -1em;
  color: rgb(253, 213, 53);
}

.icon-transfers .path11:before {
  content: "\eb2b";
  margin-left: -1em;
  color: rgb(254, 234, 154);
}

.icon-transfers .path12:before {
  content: "\eb2c";
  margin-left: -1em;
  color: rgb(254, 234, 154);
}

.icon-dowload:before {
  content: "\eb2d";
}

.icon-share:before {
  content: "\eb2e";
}

.icon-card1 .path1:before {
  content: "\eb2f";
  color: rgb(53, 85, 162);
}

.icon-card1 .path2:before {
  content: "\eb30";
  margin-left: -1em;
  color: rgb(27, 53, 85);
}

.icon-card1 .path3:before {
  content: "\eb31";
  margin-left: -1em;
  color: rgb(83, 216, 227);
}

.icon-card1 .path4:before {
  content: "\eb32";
  margin-left: -1em;
  color: rgb(24, 168, 201);
}

.icon-card1 .path5:before {
  content: "\eb33";
  margin-left: -1em;
  color: rgb(236, 246, 253);
}

.icon-card1 .path6:before {
  content: "\eb34";
  margin-left: -1em;
  color: rgb(234, 246, 255);
}

.icon-card1 .path7:before {
  content: "\eb35";
  margin-left: -1em;
  color: rgb(214, 232, 254);
}

.icon-points .path1:before {
  content: "\eb36";
  color: rgb(240, 196, 25);
}

.icon-points .path2:before {
  content: "\eb37";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path3:before {
  content: "\eb38";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path4:before {
  content: "\eb39";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path5:before {
  content: "\eb3a";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path6:before {
  content: "\eb3b";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path7:before {
  content: "\eb3c";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path8:before {
  content: "\eb3d";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path9:before {
  content: "\eb3e";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path10:before {
  content: "\eb3f";
  margin-left: -1em;
  color: rgb(242, 156, 31);
}

.icon-points .path11:before {
  content: "\eb40";
  margin-left: -1em;
  color: rgb(240, 196, 25);
}

.icon-points .path12:before {
  content: "\eb41";
  margin-left: -1em;
  color: rgb(229, 126, 37);
}

.icon-points .path13:before {
  content: "\eb42";
  margin-left: -1em;
  color: rgb(229, 126, 37);
}

.icon-points .path14:before {
  content: "\eb43";
  margin-left: -1em;
  color: rgb(242, 156, 31);
}

.icon-qrcode4:before {
  content: "\eb44";
}

.icon-group .path1:before {
  content: "\eb45";
  color: rgb(250, 190, 44);
}

.icon-group .path2:before {
  content: "\eb46";
  margin-left: -1em;
  color: rgb(255, 145, 0);
}

.icon-group .path3:before {
  content: "\eb47";
  margin-left: -1em;
  color: rgb(255, 230, 102);
}

.icon-group .path4:before {
  content: "\eb48";
  margin-left: -1em;
  color: rgb(250, 190, 44);
}

.icon-group .path5:before {
  content: "\eb49";
  margin-left: -1em;
  color: rgb(250, 190, 44);
}

.icon-group .path6:before {
  content: "\eb4a";
  margin-left: -1em;
  color: rgb(255, 145, 0);
}

.icon-group .path7:before {
  content: "\eb4b";
  margin-left: -1em;
  color: rgb(92, 112, 153);
}

.icon-group .path8:before {
  content: "\eb4c";
  margin-left: -1em;
  color: rgb(158, 226, 248);
}

.icon-group .path9:before {
  content: "\eb4d";
  margin-left: -1em;
  color: rgb(64, 74, 128);
}

.icon-group .path10:before {
  content: "\eb4e";
  margin-left: -1em;
  color: rgb(230, 46, 107);
}

.icon-group .path11:before {
  content: "\eb4f";
  margin-left: -1em;
  color: rgb(0, 221, 128);
}

.icon-group .path12:before {
  content: "\eb50";
  margin-left: -1em;
  color: rgb(0, 170, 149);
}

.icon-recharge .path1:before {
  content: "\e901";
  color: rgb(224, 62, 62);
}

.icon-recharge .path2:before {
  content: "\eb51";
  margin-left: -1em;
  color: rgb(54, 206, 148);
}

.icon-recharge .path3:before {
  content: "\eb52";
  margin-left: -1em;
  color: rgb(254, 189, 85);
}

.icon-recharge .path4:before {
  content: "\eb53";
  margin-left: -1em;
  color: rgb(47, 179, 128);
}

.icon-recharge .path5:before {
  content: "\eb54";
  margin-left: -1em;
  color: rgb(43, 43, 55);
}

.icon-recharge .path6:before {
  content: "\eb55";
  margin-left: -1em;
  color: rgb(47, 179, 128);
}

.icon-recharge .path7:before {
  content: "\eb56";
  margin-left: -1em;
  color: rgb(189, 111, 8);
}

.icon-recharge .path8:before {
  content: "\eb57";
  margin-left: -1em;
  color: rgb(255, 97, 97);
}

.icon-recharge .path9:before {
  content: "\eb58";
  margin-left: -1em;
  color: rgb(237, 244, 250);
}

.icon-recharge .path10:before {
  content: "\eb59";
  margin-left: -1em;
  color: rgb(203, 53, 65);
}

.icon-recharge .path11:before {
  content: "\eb5a";
  margin-left: -1em;
  color: rgb(224, 62, 62);
}

.icon-recharge .path12:before {
  content: "\eb5b";
  margin-left: -1em;
  color: rgb(193, 207, 232);
}

.icon-recharge .path13:before {
  content: "\eb5c";
  margin-left: -1em;
  color: rgb(168, 30, 41);
}

.icon-recharge .path14:before {
  content: "\eb5d";
  margin-left: -1em;
  color: rgb(42, 161, 115);
}

.icon-recharge .path15:before {
  content: "\eb5e";
  margin-left: -1em;
  color: rgb(47, 179, 128);
}

.icon-recharge .path16:before {
  content: "\eb5f";
  margin-left: -1em;
  color: rgb(47, 179, 128);
}

.icon-recharge .path17:before {
  content: "\eb60";
  margin-left: -1em;
  color: rgb(42, 161, 115);
}

.icon-recharge .path18:before {
  content: "\eb61";
  margin-left: -1em;
  color: rgb(203, 53, 65);
}

.icon-recharge .path19:before {
  content: "\eb62";
  margin-left: -1em;
  color: rgb(252, 158, 32);
}

.icon-recharge .path20:before {
  content: "\eb63";
  margin-left: -1em;
  color: rgb(252, 158, 32);
}

.icon-recharge .path21:before {
  content: "\eb64";
  margin-left: -1em;
  color: rgb(252, 158, 32);
}

.icon-calendar3:before {
  content: "\eb65";
}

.icon-face-ID:before {
  content: "\eb66";
}

.icon-passpost:before {
  content: "\eb67";
}

.icon-bell:before {
  content: "\e900";
}

.icon-checkmark:before {
  content: "\eab0";
}
