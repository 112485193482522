.box-service {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 11px 12px;
  justify-content: center;
}

.box-service li a {
  background: #000;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
}

.min-h-350px {
  min-height: 350px;
}

.forward-btn {
  width: 16px;
  position: absolute;
  right: 30px;
  border: none;
  background: transparent;
  padding: 0;
  color: #1e1e1e;

  i {
    font-size: 38px;
    color: #1e1e1e;
  }
}

.tf-navigation-bar {
  max-width: 300px;
  margin: 0 auto;
}

.bgi-size-cover {
  background-position: center !important;
}

.icon-home2:before {
  color: #717171;
}

.active .icon-home2:before {
  color: #000;
}

.MuiButtonBase-root {
  width: auto;
}

.footer-fonticon {
  font-size: 23px;
  color: #717171;
}

.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root {
  font-size: 2.125rem;
}

.MuiDateTimePickerToolbar-timeContainer {
  margin-top: 26px;
}

.css-1e4tpwf-MuiTypography-root-MuiPickersToolbarText-root-MuiDateTimePickerToolbar-separator {
  font-size: 2.125rem;
  line-height: 144%;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.error {
  color: red;
  margin: 10px 0;
  font-weight: bold;
}

.dropdown-options {
  .fieldset-radio {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label {
      margin-left: 6px;
      margin-bottom: 0;
    }
  }
}

.icon-notification1 span {
  font-size: 9px;

  background: #ea3434 !important;
}

.right-30 {
  position: absolute;
  right: 30px;
}

.hero-logo {
  width: 120px;
}

.replies-box {
  padding: 0px 20px;
  border-bottom: 1px #eee solid;
  padding-bottom: 20px;

  .image-box {
    display: flex;
    align-items: baseline;

    time {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .reply-item {
    margin-bottom: 15px;
  }
}

p,
span {
  background: transparent !important;
  background-color: transparent !important;
}

.reply-box {
  padding: 0 20px;

  textarea {
    padding: 10px;

    &:focus {
      box-shadow: none !important;
    }
  }
}
